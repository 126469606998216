var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('a-card',{staticStyle:{"padding-bottom":"0px"}},[_c('a-row',[_c('a-col',{attrs:{"span":24}},[_c('ButtonsPicker',{attrs:{"radioValue":_vm.radioValue,"dateRange":_vm.searchForm.dateRange},on:{"aRangePickerChange":(value) => {
              _vm.$set(_vm.searchForm, 'dateRange', value);
              _vm.radioValue = null;
              _vm.search();
            },"aRadioGroupChange":(value) => {
              _vm.radioValue = value;
            }}})],1)],1)],1),_c('a-row',{staticStyle:{"margin-top":"8px"},attrs:{"gutter":"8"}},[_c('a-col',{attrs:{"span":4}},[_c('a-card',{attrs:{"hoverable":""}},[_c('a-statistic',{attrs:{"title":"销售金额(元)","value":_vm.statistics.total_sales_amount,"loading":_vm.loading}})],1)],1),_c('a-col',{attrs:{"span":4}},[_c('a-card',{attrs:{"hoverable":""}},[_c('a-statistic',{attrs:{"title":"销售退货金额(元)","value":_vm.statistics.total_sales_reutrn_amount,"loading":_vm.loading}})],1)],1),_c('a-col',{attrs:{"span":4}},[_c('a-card',{attrs:{"hoverable":""}},[_c('a-statistic',{attrs:{"title":"采购金额(元)","value":_vm.statistics.total_purchase_amount,"loading":_vm.loading}})],1)],1),_c('a-col',{attrs:{"span":4}},[_c('a-card',{attrs:{"hoverable":""}},[_c('a-statistic',{attrs:{"title":"采购退货金额(元)","value":_vm.statistics.total_purchase_return_amount,"loading":_vm.loading}})],1)],1),_c('a-col',{attrs:{"span":4}},[_c('a-card',{attrs:{"hoverable":""}},[_c('a-statistic',{attrs:{"title":"收入金额(元)","value":_vm.statistics.total_income_amount,"loading":_vm.loading}})],1)],1),_c('a-col',{attrs:{"span":4}},[_c('a-card',{attrs:{"hoverable":""}},[_c('a-statistic',{attrs:{"title":"支出金额(元)","value":_vm.statistics.total_expenditure_amount,"loading":_vm.loading}})],1)],1)],1),_c('a-row',{staticStyle:{"margin-top":"8px"},attrs:{"gutter":"8"}},[_c('a-col',{attrs:{"span":12}},[_c('sales-detail',{ref:"salesDetail"})],1),_c('a-col',{attrs:{"span":12}},[_c('sales-return-detail',{ref:"salesReturnDetail"})],1)],1),_c('a-row',{staticStyle:{"margin-top":"8px"},attrs:{"gutter":"8"}},[_c('a-col',{attrs:{"span":12}},[_c('purchase-detail',{ref:"purchaseDetail"})],1),_c('a-col',{attrs:{"span":12}},[_c('purchase-return-detail',{ref:"purchaseReturnDetail"})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }